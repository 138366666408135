<template>
  <div class="service_details_container">
    <img class="title_image" src="../../assets/img_shipping.png" />
    <h4>START SHIPPING</h4>
    <div class="step_view_wrapper">
      <div class="strip">
        <div class="strip_half"></div>
        <div class="strip_half"></div>
        <div class="circle">
          <div class="circle_labels">SERVICE OPTIONS</div>
        </div>
        <div class="circle two inactive">
          <div class="circle_labels">SHIPMENT DETAILS</div>
        </div>
        <div class="circle three inactive">
          <div class="circle_labels">CONFIRM SHIPMENT</div>
        </div>
      </div>
    </div>
   <!--  <section>
      <h2>CHOOSE ENTRY METHOD</h2>
      <div class="radio_wrapper1">
        <label>
          <input type="radio" name="entrychoose" value="single" id="single" v-model="draft.entry_type" />
          <span>Single</span>
        </label>
        <label>
          <input type="radio" name="entrychoose" value="batch" id="batch" v-model="draft.entry_type"  :disabled="editType == true"/>
          <span>Batch</span>
        </label>
      </div>
    </section> -->
    <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
      <section>
        <h2>SERVICE OPTIONS</h2>
        <div class="form_section1">
          <table>
            <tr>
              <td>
                <ValidationProvider name="requester name" rules="required|max:100" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Requester Name
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Name Character Limit 100" v-model="draft.requester_name" :maxlength="max4" :class="errors[0] ? 'warning-border':''"/>
                  </div>
                </ValidationProvider>
              </td>
              <td>
                <ValidationProvider name="unit name" rules="required" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                  <label>
                    Units / Branch
                    <span>*</span>
                  </label>
                  <select v-model="draft.branch_id" :class="errors[0] ? 'warning-border':''">
                    <option value="">Select a branch</option>
                    <option v-for="(branch,i) in branches" :key="'branches'+i" :value="branch.id">{{branch.name}}</option>
                  </select>
                </div>
                </ValidationProvider>
              </td>
              <td>
                <div class="label_and_element_wrapper">
                  <label>
                    Request Date
                    <span>*</span>
                  </label>
                  <flat-pickr
                    class="date_for_pos_fixed"
                    v-model="draft.request_date"
                    :config="configDate"
                    placeholder="Date"
                    name="birthdate"
                  ></flat-pickr>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <ValidationProvider name="type of good" rules="required" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                  <label>
                    Type of Goods
                    <span>*</span>
                  </label>
                  <select v-model="draft.type_of_good" @change="fetchPackageType" :class="errors[0] ? 'warning-border':''">
                    <option value="" disabled>Select package type</option>
                    <option v-for="(good,i) in goods" :key="'goods'+i" :value="good.id">{{good.type}}</option>
                  </select>
                </div>
                </ValidationProvider>
              </td>
              <td>
                <ValidationProvider name="mode of dispatch" rules="required" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                  <label>
                    Mode of Dispatch
                    <span>*</span>
                  </label>
                  <select v-model="draft.mode_of_dispatch" :class="errors[0] ? 'warning-border':''">
                    <option value="" disabled>Select mode of dispatch</option>
                    <option v-for="(dispatch,i) in dispatches" :key="'dispatches'+i" :value="dispatch.id">{{dispatch.name}}</option>
                  </select>
                </div>
                </ValidationProvider>
              </td>
              <td></td>
            </tr>
          </table>
        </div>

        <div class="form_section1" v-if="draft.mode_of_dispatch">
          <label class="radio_with_img_labels" v-for="(provider,i) in providers" :key="'provider'+i">
            
            <!-- <a class="view_rate_card" v-if="provider.rate_card != 'null' " :href="provider.rate_card" target="_blank">
              <img :src="provider.logo" :alt="provider.name" />
            </a> -->

            <div v-if="provider.rate_card != 'null' ">
              <a class="view_rate_card" :href="provider.rate_card" target="_blank">
              <img :src="provider.logo" :alt="provider.name" />
            </a>
            </div>
            <div v-else>
              <img :src="provider.logo" :alt="provider.name" />
            </div>

            <input type="radio" :checked="provider.id == draft.provider_id" name="provider" :ref="provider.id" @click="selectRateCard(provider)" />
          </label>
        </div>
      </section>

      <section>
        <h2>DELIVERY OPTIONS</h2>
        <div class="form_section1">
          <div class="special_panel">
            <header>Service Name</header>
            <div class="sp_body">
              <div class="a_part">
                <ValidationProvider name="type of good" rules="required" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper sp">
                  <label>
                    Choose a delivery service
                    <span>*</span>
                  </label>
                  <select v-model="draft.service_id"  :class="errors[0] ? 'warning-border':''">
                    <option value="">Select service</option>
                    <option v-for="(service,i) in services" :value="service.id" :key="'services'+i">{{service.name}}</option>
                  </select>
                </div>
                </ValidationProvider>
                <div class="service_logo">
                  <img :src="provider_details.logo" :alt="provider_details.name" />
                </div>
              </div>
              <div class="a_part right_adj">
                <div class="infos">{{provider_details.description}}</div>
                <div v-if="provider_details.rate_card != 'null' ">
                  <a class="view_rate_card" v-if="provider_details.rate_card  " :href="provider_details.rate_card" target="_blank">
                    View Rate Card
                    <i class="material-icons">receipt</i>
                  </a>
                </div>
                <!-- <div v-else>
                    <h4>No rate card found</h4>
                </div> -->
              </div>
              <div style="clear:both"></div>
            </div>
          </div>
          <table>
            <tr>
              <td>
                <!-- <ValidationProvider name="Customer reference number" rules="required|max:30" v-slot="{ errors }" slim> -->
                <div class="label_and_element_wrapper">
                  <label>
                    Customer reference number
                  </label>
                  <input type="text" placeholder="Enter Character Limit 30" v-model="draft.reference_no" :maxlength="max"/>
                </div>
                <!-- </ValidationProvider> -->
              </td>
              <td>
                <!-- <ValidationProvider name="PTAEO" rules="required" v-slot="{ errors }" slim>
                <div class="label_and_element_wrapper">
                  <label>
                    PTAEO
                    <span>*</span>
                  </label>
                  <input type="text" placeholder="Enter PTAEO" v-model="draft.ptaeo" @focus="ptaeo_suggestion" @keyup="ptaeo_suggestion" @blur="hideptaeo_suggestion = false" :class="errors[0] ? 'warning-border':''"/>
                  <div class="dynamic_data_wrapper" v-show="hideptaeo_suggestion">
                    <span 
                          v-for="(ptaeosuggest,i) in select_ptaeo_suggestions" :key="'ptaeo_suggestion_list_'+i"
                          @mousedown="ptaeoSuggestionSelected(ptaeosuggest.ptaeo)"
                          >
                          {{ ptaeosuggest.ptaeo }}
                        </span>
                  </div>
                </div>
                </ValidationProvider> -->
              </td>
            </tr>
          </table>
      

          <!-- <label class="lone_chk_label">
            <input name="usertype" type="radio" id="standard" v-model="has_standard" value="3"/>
            <span>Standard</span>
          </label>
          <label class="lone_chk_label">
            <input name="usertype" type="radio" v-model="draft.has_priority" value="1"/>
            <span>Priority Importance</span>
          </label>
          <label class="lone_chk_label">
            <input name="usertype" type="radio" v-model="draft.has_emergency" value="2"/>
            <span>Emergency</span>
          </label> -->

        </div>
      </section>



      <h4>ADDRESS DETAILS</h4>
      <section>
        <div class="address_details_wrapper">
          <header>
            <h2>PICKUP DETAILS</h2>
            <a class="yellow_bt" @click="viewAddressBook('pickup')" v-if="auth().user_type != 'user'">VIEW ADDRESS BOOK
              <i class="material-icons">account_box</i>
            </a>
          </header>
          <div class="form_section1">
            <table>
              <tr>
                <td colspan="2">
                  <ValidationProvider name="sender name" rules="required|max:100" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Full Name
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 100" v-model="draft.pickup_name"   :class="errors[0] ? 'dynamic_data_trigger warning-border':'dynamic_data_trigger'" :disabled="auth().user_type == 'user'" @focus="show_pickup_suggestion = true" @blur="hideSuggestions" autocomplete="new-password" :maxlength="max4"/>
                      <div class="dynamic_data_wrapper" v-if="show_pickup_suggestion">
                        <span 
                          v-for="(address,i) in filteredPickupAddress" 
                          :key="'pickup_address_'+i"
                          @click="populatePickupAddress(address)"
                          >
                          {{address.name}}
                        </span>
                      </div>
                  </div>
                </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <ValidationProvider name="pickup company name" rules="required|max:80" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Company Name
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 80" v-model="draft.pickup_company" autocomplete="new-password" :class="errors[0] ? 'warning-border':''" :maxlength="max3"/>
                  </div>
                </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <!-- <ValidationProvider name="pickup branch name" rules="required|max:30" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Units / Branch Name
                    </label>
                    <input type="text" placeholder="Enter Character Limit 60" v-model="draft.pickup_branch" autocomplete="new-password" :maxlength="max1"/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <ValidationProvider name="pickup address_1" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Address 1
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 60" v-model="draft.pickup_address_1" autocomplete="new-password" :maxlength="max1" :class="errors[0] ? 'warning-border':''"/>
                  </div>
                </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <!-- <ValidationProvider name="pickup address_2" rules="required|max:60" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Address 2
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.pickup_address_2" autocomplete="new-password" :maxlength="max1"/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
              </tr>
              <tr>
                <td>
                  <!-- <ValidationProvider name="pickup suburb" rules="required|max:30" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Suburb
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.pickup_suburb" autocomplete="new-password" :maxlength="max"/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
                <td>
                  <ValidationProvider name="pickup city" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      City
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.pickup_city"  autocomplete="new-password" :maxlength="max" :class="errors[0] ? 'warning-border':''"/>
                  </div>
                </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- <ValidationProvider name="pickup state" rules="required|max:30" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      State / Province
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.pickup_state" autocomplete="new-password" :maxlength="max"/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
                <td>
                  <!-- <ValidationProvider name="pickup postal_code" rules="required|max:10" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Postal Code
                    </label>
                    <input type="text" placeholder="Enter Character Limit 10" v-model="draft.pickup_postal_code" autocomplete="new-password" :maxlength="max2"/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <ValidationProvider name="pickup country" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Country
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.pickup_country" autocomplete="new-password" :maxlength="max" :class="errors[0] ? 'warning-border':''"/>
                  </div>
                </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- <ValidationProvider name="pickup phone" rules="required|max:30" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Phone
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.pickup_phone" autocomplete="new-password" :maxlength="max"/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
                <td>
                  <!-- <ValidationProvider name="pickup mobile" rules="required|max:30" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Mobile
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.pickup_mobile" autocomplete="new-password" :maxlength="max"/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <!-- <ValidationProvider name="pickup email" rules="required|max:60" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Email
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 60" v-model="draft.pickup_email" autocomplete="new-password" :maxlength="max1"/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="address_details_wrapper">
          <header>
            <h2>DELIVERY DETAILS</h2>
            <a class="yellow_bt" @click="viewAddressBook('delivery')">VIEW ADDRESS BOOK
              <i class="material-icons">account_box</i>
            </a>
            <div class="selected_count" @click="address_book.delivery = true">
              <i class="material-icons">account_circle</i>
              <span>{{ delivery_addresses_count }} Selected</span>
            </div>
          </header>
          <div class="form_section1">
            <table>
              <tr>
                <td colspan="2">
                  <ValidationProvider name="delivery name" rules="required|max:100" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Full Name
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 100" v-model="draft.delivery_name" @focus="show_destination_suggestion = true" @blur="hideSuggestions" autocomplete="new-password" :maxlength="max4"  :class="errors[0] ? 'dynamic_data_trigger warning-border':'dynamic_data_trigger'"/>
                      <div class="dynamic_data_wrapper" v-if="show_destination_suggestion">
                        <span 
                          v-for="(address,i) in filteredDeliveryAddress" 
                          :key="'delivery_address_'+i"
                          @click="populateDeliveryAddress(address)"
                          >
                          {{address.name}}
                        </span>
                      </div>
                  </div>
                </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <ValidationProvider name="delivery company" rules="required|max:80" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Company Name
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 80" v-model="draft.delivery_company" autocomplete="new-password" :class="errors[0] ? 'warning-border':''" :maxlength="max3"/>
                  </div>
                </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <!-- <ValidationProvider name="delivery branch" rules="required|max:30" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Units / Branch Name
                    </label>
                    <input type="text" placeholder="Enter Character Limit 60" v-model="draft.delivery_branch" autocomplete="new-password" :maxlength="max1"/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <ValidationProvider name="delivery address_1" rules="required|max:60" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Address 1
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 60" v-model="draft.delivery_address_1" autocomplete="new-password" :class="errors[0] ? 'warning-border':''" :maxlength="max1"/>
                  </div>
                </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <ValidationProvider name="delivery address_2" rules="required|max:60" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Address 2
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 60" v-model="draft.delivery_address_2" :class="errors[0] ? 'warning-border':''" autocomplete="new-password" :maxlength="max1"/>
                  </div>
                </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <!-- <ValidationProvider name="delivery suburb" rules="required|max:30" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Suburb
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.delivery_suburb" autocomplete="new-password" :maxlength="max"/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
                <td>
                  <ValidationProvider name="delivery city" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      City
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.delivery_city" autocomplete="new-password" :class="errors[0] ? 'warning-border':''" :maxlength="max"/>
                  </div>
                </ValidationProvider>
                </td>
              </tr>
              <tr>
                <td>
                  <ValidationProvider name="delivery state" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      State / Province
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.delivery_state" autocomplete="new-password" :class="errors[0] ? 'warning-border':''" :maxlength="max"/>
                  </div>
                </ValidationProvider>
                </td>
                <td>
                  <!-- <ValidationProvider name="delivery postal_code" rules="required|max:10" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Postal Code
                    </label>
                    <input type="text" placeholder="Enter Character Limit 10" v-model="draft.delivery_postal_code" autocomplete="new-password" :maxlength="max2"/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <!-- <ValidationProvider name="delivery country" rules="required|max:30" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Country
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.delivery_country" autocomplete="new-password" :maxlength="max"/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
              </tr>
              <tr>
                <td>
                  <!-- <ValidationProvider name="delivery phone" rules="required|max:30" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Phone
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.delivery_phone" autocomplete="new-password" :maxlength="max"/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
                <td>
                  <!-- <ValidationProvider name="delivery mobile" rules="required|max:30" v-slot="{ errors }" slim> -->
                  <div class="label_and_element_wrapper">
                    <label>
                      Mobile
                    </label>
                    <input type="text" placeholder="Enter Character Limit 30" v-model="draft.delivery_mobile" autocomplete="new-password" :maxlength="max"/>
                  </div>
                <!-- </ValidationProvider> -->
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <ValidationProvider name="delivery email" rules="required|max:30" v-slot="{ errors }" slim>
                  <div class="label_and_element_wrapper">
                    <label>
                      Email
                      <span>*</span>
                    </label>
                    <input type="text" placeholder="Enter Character Limit 60" v-model="draft.delivery_email" :class="errors[0] ? 'warning-border':''" autocomplete="new-password" :maxlength="max1"/>
                  </div>
                </ValidationProvider>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </section>
    </ValidationObserver>

    <section class="centered">
      <!-- <a class="link_bt bt_cancel" >Previous</a> -->
      <a class="link_bt bt_cancel"  @click="$router.push('/pages/Dashboard')">Cancel</a>
      <!-- <router-link to="/pages/ServiceUpdate" @click="submit" class="link_bt bt_save">Save and Next</router-link> -->
      <a class="link_bt bt_save" @click="submit" >Save and Next</a>
    </section>

    <!-- <div class="black-overlay" v-show="showEditContact">
      <div class="box-modal">
        <div class="body">
          <AddressBookModal v-show="showEditContact" @closeModal="showEditContact = false" />
        </div>
      </div>
    </div> -->

    <div class="black-overlay" v-if="address_book.pickup || address_book.delivery">
      <div class="box-modal">
        <div class="body">
          <AddressBookModal 
            :selectedAddresses="[{id:draft.temp_pickup_address_id}]"
              v-if="address_book.pickup" 
              @closeModal="closeModal" 
              type="pickup" 
              @selectedAddress="populatePickupAddress"
            />
          <AddressBookModal 
            v-if="address_book.delivery" 
            @closeModal="closeModal" 
            type="delivery" 
            :callfrom="draft.entry_type"
            @selectedAddress="populateDeliveryAddress"
            :selectedAddresses="draft.delivery_address"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import AddressBookModal from "./AddressBookModal.vue";
import moment from "moment";
export default {
  name: "ServiceDetails",
  components: {
    flatPickr,
    AddressBookModal
  },
  computed:{
    filteredPickupAddress(){
      return this.address_list.filter(element => {
        if(element.name.toLowerCase().includes(this.draft.pickup_name.toLowerCase())){
          return element;
        }
      })
    },
    filteredDeliveryAddress(){
      return this.address_list.filter(element => {
        if(element.name.toLowerCase().includes(this.draft.delivery_name.toLowerCase())){
          return element;
        }
      })
    }
  },
  data() {
    return {
      max: 30,
      max1: 60,
      max2: 10,
      max3: 80,
      max4: 100,
      configDate: {
        format: "d-m-Y",
        altFormat: "d-m-Y",
        altInput: true
      },
      showEditContact: false,
      delivery_addresses_count:0,
      draft:{
        request_date:moment(new Date()).format('YYYY-MM-DD'),
        entry_type:"single",
        requester_name:"",
        branch_id:"",
        type_of_good:"",
        mode_of_dispatch:"",
        carrier_code:"",
        provider_id:"",
        service_id:"",
      
        reference_no:"",
        temp_pickup_address_id:"",
        pickup_name:"" ,
        pickup_company: "",
        pickup_branch: "",
        pickup_address_1: "",
        pickup_address_2: "",
        pickup_postal_code: "",
        pickup_city: "",
        pickup_suburb: "",
        pickup_state: "",
        pickup_country: "",
        pickup_phone: "",
        pickup_mobile: "",
        pickup_email: "",
        delivery_name:"",
        delivery_company:"",
        delivery_branch:"",
        delivery_address_1:"",
        delivery_address_2:"",
        delivery_postal_code:"",
        delivery_city:"",
        delivery_suburb:"",
        delivery_state:"",
        delivery_country:"",
        delivery_phone:"",
        delivery_mobile:"",
        delivery_email:"",
        delivery_address:[],
        booking_type: "Courier",
        delivery_type:""
      },
      single:"",
      batch:"",
      delivery_name:"",
      delivery_company:"",
      delivery_branch:"",
      delivery_address_1:"",
      delivery_address_2:"",
      delivery_postal_code:"",
      delivery_city:"",
      delivery_suburb:"",
      delivery_state:"",
      delivery_country:"",
      delivery_phone:"",
      delivery_mobile:"",
      delivery_email:"",
      address_book:{
        pickup:false,
        delivery:false
      },
      provider_details:"",
      show_pickup_suggestion:false,
      show_destination_suggestion:false,
      pickupAddressCount: 0,
      selectedAddresses: [],
      branches:[],
      dispatches:[],
      goods:[],
      providers:[],
      services:[],
     
      address_list:[],
      editType:true,
      editTypeboolean:"",
    };
  },
  created(){
    this.$emit("breadCrumbData",["Create Booking","Courier Booking"]);

    this.editTypeboolean = this.$route.params.type;
    if(this.editTypeboolean == "edit"){
      this.editType= true;
    }else{
      this.editType= false;
    }
/*
    if(this.draft.has_emergency == 0 && this.draft.has_priority == 0){
      this.has_standard = 3;
    }
*/
    if(this.$route.params.id == undefined ){
      this.axios.get("/api/user/data")
      .then(response => {
        console.log(response);
          this.draft.requester_name = response.data.msg.name;
          this.draft.branch_id = response.data.msg.branch.id;
          this.draft.type_of_good = response.data.msg.goods.id;
          this.fetchDispatch();
      })
      .catch(error =>{
        console.log(error);
      });

      this.fetchUserData();
      
    } else {
      this.axios.get("/api/draft/"+this.$route.params.id)
      .then(response => {
        console.log(response);
          this.draft.entry_type=response.data.draft.entry_type;
          /*if(this.draft.entry_type == "single"){
            this.single = this.draft.entry_type;
          } else {
            this.draft.entry_type = "batch";
            this.batch = "batch";
          }*/

          console.log(this.$route.params.type);
          this.editTypeboolean = this.$route.params.type;
          if(this.editTypeboolean == "edit"){
            this.editType= true;
            if(this.draft.entry_type == "single"){
              this.single = this.draft.entry_type;
            } else {
              this.draft.entry_type = "single";
              // this.batch = "batch";
            }
          }else{
            this.editType= false;
            if(this.draft.entry_type == "single"){
              this.single = this.draft.entry_type;
            } else {
              this.draft.entry_type = "batch";
              // this.batch = "batch";
            }
          }


          this.delivery_addresses_count = response.data.count;
          this.draft.request_date=response.data.draft.request_date;
          this.draft.requester_name=response.data.draft.requester_name;
          this.draft.branch_id=response.data.draft.branch_id;
          this.draft.type_of_good=response.data.draft.type_of_good;
          this.draft.mode_of_dispatch=response.data.draft.mode_of_dispatch ;
          this.draft.carrier_code=response.data.draft.carrier_code;
          this.draft.provider_id=response.data.draft.provider.id;
          this.draft.service_id=response.data.draft.service_id;
          this.draft.pickup_name=response.data.draft.pickup_name;
          this.draft.pickup_company=response.data.draft.pickup_company;
          this.draft.pickup_branch=response.data.draft.pickup_branch;
          this.draft.pickup_address_1=response.data.draft.pickup_address_1;
          this.draft.pickup_address_2=response.data.draft.pickup_address_2;
          this.draft.pickup_city=response.data.draft.pickup_city;
          this.draft.pickup_state=response.data.draft.pickup_state;
          this.draft.pickup_postal_code=response.data.draft.pickup_postal_code;
          this.draft.pickup_country=response.data.draft.pickup_country;
          this.draft.pickup_phone=response.data.draft.pickup_phone;
          this.draft.pickup_email=response.data.draft.pickup_email;
          this.draft.delivery_name=response.data.draft.delivery_name;
          this.draft.delivery_branch=response.data.draft.delivery_branch;
          this.draft.delivery_company=response.data.draft.delivery_company;
          this.draft.delivery_address_1=response.data.draft.delivery_address_1;
          this.draft.delivery_address_2=response.data.draft.delivery_address_2;
          this.draft.delivery_city=response.data.draft.delivery_city;
          this.draft.delivery_state=response.data.draft.delivery_state;
          this.draft.delivery_suburb=response.data.draft.delivery_suburb;
          this.draft.delivery_postal_code=response.data.draft.delivery_postal_code;
          this.draft.delivery_country=response.data.draft.delivery_country;
          this.draft.delivery_phone=response.data.draft.delivery_phone;
          this.draft.delivery_email=response.data.draft.delivery_email;
          this.draft.reference_no=response.data.draft.reference_no;
          this.draft.booking_type=response.data.draft.booking_type;
          
          
          if(response.data.address_book_id && response.data.address_book_id.length > 0){
            this.mapDeliveryAddresses(response.data.address_book_id,false);
            this.draft.address_book_id = response.data.address_book_id[0].id ? response.data.address_book_id[0].id : "";
          }else{
            this.draft.address_book_id = "sbdhcbwkds";
          }
            console.log(this.draft.booking_type);

          if(this.draft.booking_type != "Bulk Booking"){
  
            if(this.draft.type_of_good){
              this.axios.get("/api/goods/"+this.draft.type_of_good)
              .then(response => {
                console.log(response);
                  this.dispatches = response.data.goods.modes;
                  if(this.dispatches.length > 0){
                    this.draft.mode_of_dispatch = this.dispatches[0].id;
                  }
              })
              .catch(error =>{
                  console.log(error);
              });
            }

          } else {
     
            this.axios.get("/api/dispatch/")
            .then(response => {
                console.log(response);
                this.dispatches = response.data.mode_of_dispatch;
            })
            .catch(error =>{
                console.log(error);
            });

          }
            
        console.log(this.draft.mode_of_dispatch);

          if(this.draft.carrier_code){
            this.axios.get("/api/provider/"+ this.draft.carrier_code)
            .then(response => {
            console.log(response);
                this.services = response.data.provider.services;
                
                this.provider_details = response.data.provider;
            })
            .catch(error => {
                console.log(error);
            });
          }
      })
      .catch(error =>{
        console.log(error);
      });
    }
      this.fetchBranch();
      this.fetchPackageType();
      this.fetchAddresses();
      // this.fetchProviders();
  },
  methods: {
    hideSuggestions(){
      setTimeout(() => {
        this.show_pickup_suggestion = false;
        this.show_destination_suggestion = false;
      },200);
    },
    fetchBranch(){
      this.axios.get("/api/branch/")
      .then(response => {
        this.branches = response.data.branches;
      })
      .catch(error => {
        console.log(error);
      });
    },
    fetchPackageType(){
      this.axios.get("/api/goods/")
      .then(response => {
        this.goods = response.data.goods;
          this.fetchDispatch();
          this.selectRateCard();
      })
      .catch(error => {
        console.log(error);
      });
    },
    fetchDispatch(){
      if(this.draft.type_of_good){
        this.axios.get("/api/goods/"+this.draft.type_of_good)
        .then(response => {
          console.log(response);
            this.dispatches = response.data.goods.modes;
            if(this.dispatches.length > 0){
              this.draft.mode_of_dispatch = this.dispatches[0].id;
            }
        })
        .catch(error =>{
            console.log(error);
        });
      }
    },
   
    fetchProviders(){
        console.log(this.draft.mode_of_dispatch);
      if(this.draft.mode_of_dispatch){
        this.axios.get("/api/dispatch/provider/"+ this.draft.mode_of_dispatch)
        .then(response => {
        console.log(response);
            this.providers = response.data.mode_of_dispatch.providers;
        })
        .catch(error => {
            console.log(error);
        });
      }
    },
    selectRateCard(provider) {
      
      if(provider != undefined) {
        this.draft.provider_id = provider.id;
        this.draft.carrier_code = provider.carrier_code;
        if(provider.carrier_code){
          this.axios.get("/api/provider/"+ provider.carrier_code)
          .then(response => {
          console.log(response);
              this.services = response.data.provider.services;
              if(this.services.length > 0){
                this.draft.service_id = this.services[0].id;
              }
              this.provider_details = response.data.provider;
          })
          .catch(error => {
              console.log(error);
          });
        }
      } else {
        this.services = "";
        this.provider_details = "";
      }
    },
    viewAddressBook(type){
      console.log(type);
      Object.keys(this.address_book).map(element => {
        this.address_book[element] = false;
      });
      this.address_book[type] = true;
    },
    fetchUserData(){
      this.loading = true;
      this.axios.get("/api/user/data")
      .then(response => {
        console.log(response);
          this.draft.pickup_name = response.data.msg.name;
          this.draft.pickup_branch = response.data.msg.branch ? response.data.msg.branch.name : "";
          this.draft.pickup_address_1 = response.data.msg.address_1;
          this.draft.pickup_address_2 = response.data.msg.address_2;
          this.draft.pickup_postal_code = response.data.msg.postal_code;
          this.draft.pickup_city = response.data.msg.city;
          this.draft.pickup_suburb = response.data.msg.suburb;
          this.draft.pickup_state = response.data.msg.state;
          this.draft.pickup_country = response.data.msg.country;
          this.draft.pickup_phone = response.data.msg.phone;
          this.draft.pickup_mobile = response.data.msg.mobile;
          this.draft.pickup_email = response.data.msg.email;
          this.draft.pickup_company = response.data.msg.company.name;
          this.show_pickup_suggestion = false;
          // this.loading = false;
      })
      .catch(error =>{
          console.log(error);
      });
    },
    populatePickupAddress(value){
      console.log(value);
      this.draft.temp_pickup_address_id = value.id;
      this.draft.pickup_name = value.name;
      this.draft.pickup_branch = value.branch_name;
      this.draft.pickup_company = value.company_name;
      this.draft.pickup_address_1 = value.address_1;
      this.draft.pickup_address_2 = value.address_2;
      this.draft.pickup_postal_code = value.postal_code;
      this.draft.pickup_city = value.city;
      this.draft.pickup_suburb = value.suburb;
      this.draft.pickup_state = value.state;
      this.draft.pickup_country = value.country;
      this.draft.pickup_phone = value.phone;
      this.draft.pickup_mobile = value.mobile;
      this.draft.pickup_email = value.email;
    },
    populateDeliveryAddress(addresses){
      console.log(addresses);
      if(this.draft.entry_type == "single"){
        addresses = [addresses];
      }
      let selectedAddresses = addresses[0];
      console.log(selectedAddresses);
      this.draft.delivery_name = selectedAddresses.name;
      this.draft.delivery_company = selectedAddresses.company_name;
      this.draft.delivery_branch = selectedAddresses.branch_name;
      this.draft.delivery_department = selectedAddresses.department ? selectedAddresses.department.department_name : "";
      this.draft.delivery_address_1 = selectedAddresses.address_1;
      this.draft.delivery_address_2 = selectedAddresses.address_2;
      this.draft.delivery_postal_code = selectedAddresses.postal_code;
      this.draft.delivery_city = selectedAddresses.city;
      this.draft.delivery_suburb = selectedAddresses.suburb;
      this.draft.delivery_state = selectedAddresses.state;
      this.draft.delivery_country = selectedAddresses.country;
      this.draft.delivery_phone = selectedAddresses.phone;
      this.draft.delivery_mobile = selectedAddresses.mobile;
      this.draft.delivery_email = selectedAddresses.email;
      this.draft.address_book_id = selectedAddresses.id;

      this.mapDeliveryAddresses(addresses);
      this.delivery_addresses_count = this.draft.delivery_address.length;
      this.show_destination_suggestion = false;
    },
    mapDeliveryAddresses(addresses,selected_from_address_book = true){
      let i = 0;
      this.draft.delivery_address = [];
      addresses.forEach(address => {
          this.draft.delivery_address[i] = {
            delivery_name : address.name,
            delivery_company : address.company_name,
            delivery_branch : address.branch_name,
            delivery_department : address.department ? address.department.department_name:"",
            delivery_address_1 : address.address_1,
            delivery_address_2 : address.address_2,
            delivery_postal_code : address.postal_code,
            delivery_city : address.city,
            delivery_suburb : address.suburb,
            delivery_state : address.state,
            delivery_country : address.country,
            delivery_phone : address.phone,
            delivery_mobile : address.mobile,
            delivery_email : address.email,
            address_book_id : selected_from_address_book ? address.id : address.address_book_id ? address.address_book_id : address.id
          };
          i++;
      });
    },
    addressSelected(selectedAddresses){
        console.log(selectedAddresses); // You will get the address here
    },
    fetchAddresses(){
      this.axios.post("api/address/list")
        .then(response => {
          console.log(response);
          this.address_list = response.data.msg;
        })
        .catch(error => {
          console.log(error);
        });
    },
    closeModal(value){
      this.address_book[value.type] = false;
    },
    setDefault(i){
      this.department.location.forEach(element => {
        element.is_default = 0;
      });
      this.department.location[i].is_default = 1;
    },
    submit(){
       console.log(this.$route.params.type);
     if(this.$route.params.type != undefined){
        this.editTypeboolean = this.$route.params.type;
     }

    

        console.log(this.draft);
        this.$refs.observer.validate();
        if(this.$route.params.id){
          this.draft.delivery_address[0] = {
            delivery_name : this.draft.delivery_name,
            delivery_company : this.draft.delivery_company,
            delivery_branch : this.draft.delivery_branch,
            delivery_department : this.draft.delivery_department,
            delivery_address_1 : this.draft.delivery_address_1,
            delivery_address_2 : this.draft.delivery_address_2,
            delivery_postal_code : this.draft.delivery_postal_code,
            delivery_city : this.draft.delivery_city,
            delivery_suburb : this.draft.delivery_suburb,
            delivery_state : this.draft.delivery_state,
            delivery_country : this.draft.delivery_country,
            delivery_phone : this.draft.delivery_phone,
            delivery_mobile : this.draft.delivery_mobile,
            delivery_email : this.draft.delivery_email,
            address_book_id : this.draft.address_book_id
          };
          this.axios.put("/api/draft/"+this.$route.params.id+"/first",this.draft)
          .then(response => {
            console.log(response);
            this.toast.success(response.data.msg);
            
            if(this.$route.params.type != undefined){
              this.editTypeboolean = this.$route.params.type;
              this.$router.push("/pages/ServiceUpdate/"+this.$route.params.id+"/"+this.editTypeboolean);
            } else {
              this.$router.push("/pages/ServiceUpdate/"+this.$route.params.id);
            }
          })
          .catch(error =>{
            console.log(error);
          });

        } else {
          this.draft.delivery_address[0] = {
            delivery_name : this.draft.delivery_name,
            delivery_company : this.draft.delivery_company,
            delivery_branch : this.draft.delivery_branch,
            delivery_department : this.draft.delivery_department,
            delivery_address_1 : this.draft.delivery_address_1,
            delivery_address_2 : this.draft.delivery_address_2,
            delivery_postal_code : this.draft.delivery_postal_code,
            delivery_city : this.draft.delivery_city,
            delivery_suburb : this.draft.delivery_suburb,
            delivery_state : this.draft.delivery_state,
            delivery_country : this.draft.delivery_country,
            delivery_phone : this.draft.delivery_phone,
            delivery_mobile : this.draft.delivery_mobile,
            delivery_email : this.draft.delivery_email,
            address_book_id : this.draft.address_book_id ?this.draft.address_book_id : "htfjfgjhgjg"
          };
          this.axios.post("/api/draft/",this.draft)
          .then(response => {
            let id=response.data.id;
            this.toast.success(response.data.msg);
            this.$router.push("/pages/ServiceUpdate/"+id);
          })
          .catch(error =>{
            console.log(error);
          });
        }
 
      
    }
  },
  watch:{
    'draft.mode_of_dispatch'(){
      this.fetchProviders();
    }
  }
};
</script>
<style scoped>
.service_details_container {
  padding: 20px;
}
.service_details_container > section {
  padding: 20px 0 0 0;
}
.service_details_container > section h2 {
  font-size: 20px;
  padding: 10px 0 10px 0;
  border-bottom: 4px solid #666;
  display: inline-block;
}
.title_image {
  display: block;
  margin: auto;
}
h4 {
  font-weight: 600;
  text-align: center;
  padding: 30px 0;
  font-size: 18px;
}
.step_view_wrapper {
  width: 100%;
  height: 140px;
  background: #181818;
  padding: 20px 80px;
  position: relative;
}
.step_view_wrapper .strip {
  width: calc(100% - 160px);
  height: 10px;
  background: #666;
  position: absolute;
  top: 50%;
  line-height: 0;
}
.step_view_wrapper .strip_half {
  height: 10px;
  width: 50%;
  background: #06A5ED;
  display: inline-block;
  display: none;
}
.step_view_wrapper .circle {
  width: 30px;
  height: 30px;
  background: #000;
  border: 5px solid #06A5ED;
  border-radius: 100px;
  position: absolute;
  left: -10px;
  top: -10px;
}
.step_view_wrapper .circle.inactive {
  background: #ffffff;
  border-color: #999;
}
.step_view_wrapper .circle.two {
  left: 50%;
  margin-left: -10px;
}
.step_view_wrapper .circle.three {
  left: 100%;
  margin-left: -10px;
}
.step_view_wrapper .circle_labels {
  font-size: 14px;
  text-align: center;
  color: #fff;
  position: absolute;
  width: 120px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  top: 40px;
  left: -60px;
}

.radio_wrapper1 {
  width: 300px;
  height: 100px;
  background: #fcfcfc;
  display: flex;
  padding: 20px 0;
}
.radio_wrapper1 label {
  width: auto;
  height: 30px;

  display: block;
  margin: 5px;
}
.radio_wrapper1 label input[type="radio"] {
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-right: 10px;
}
.radio_wrapper1 label span {
  width: auto;
  height: 20px;
  display: inline-block;
  margin-right: 30px;
  position: relative;
  top: -5px;
}

.radio_with_img_labels {
  width: 180px;
  height: 80px;
  background: #fff;
  color: #333;
  padding: 5px;
  margin: 10px 20px;
  display: inline-block;
}
.radio_with_img_labels img {
  display: block;
  margin: auto;
  height: 40px;
}
.radio_with_img_labels input[type="radio"] {
  width: 20px;
  height: 20px;
  display: block;
  margin: auto;
}

.special_panel {
  background: #fff;
  width: 100%;
}
.special_panel header {
  height: 40px;
  background: #333;
  color: #ccc;
  font-size: 16px;
  line-height: 40px;
  padding: 0 20px;
}
.special_panel .sp_body {
  background: #fff;
  padding: 20px;
}
.special_panel .sp_body .a_part {
  width: 50%;
  display: block;
  position: relative;
  height: 100%;
   float:left;
}
.special_panel .sp_body .a_part.right_adj{
  
  padding-top:40px;
}
.special_panel .sp_body .a_part .infos {
  padding: 20px 0;
  color: #333;
  font-size: 16px;
}
.special_panel .sp_body .a_part .view_rate_card {
  display: block;
  background: #06A5ED;
  color: #fff;
  width: 160px;
  height: 40px;
  line-height: 40px;
  text-align: center;
   position: relative;
  left: 0;
  bottom: 0;
}
.label_and_element_wrapper.sp {
  width: 70%;
}
.lone_chk_label {
  padding: 20px;
  display: inline-block;
}
.lone_chk_label span {
  display: inline-block;
  position: relative;
  top: -5px;
}
.lone_chk_label input {
  display: inline-block;
  margin-right: 10px;
  width: 20px;
  height: 20px;
}
.address_details_wrapper {
  width: 45%;
  height: auto;
  display: inline-block;
  margin: 0 2%;
}
.address_details_wrapper header {
  height: 60px;
}
.address_details_wrapper header h2 {
  font-size: 20px;
  padding: 10px 0 10px 0;
  border-bottom: 4px solid #666;
  display: block;
  float: left;
}
.address_details_wrapper header .yellow_bt {
  font-size: 20px;
  padding: 0 10px;
  float: right;
  width: 200px;
  height: 40px;
  background: gold;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  display: block;
}
.address_details_wrapper header .yellow_bt i {
  font-size: 24px;
}
.service_logo {
  width: 300px;
  height: auto;
  padding: 20px 0;
}
.service_logo img {
  display: block;
  width: 100%;
  height: auto;
}
.selected_count {
  width: 130px;
  height: 40px;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 40px;
  color: rgb(8, 82, 243);
  float: right;
  box-shadow: 0 0 13px 1px rgba(0, 0, 0, 0.1);
  position: relative;
  float: right;
  margin-right: 20px;
}
.selected_count .material-icons {
  font-size: 40px;
  top: -1px;
  left: -10px;
  position: relative;
  margin-right: 0;
}
.selected_count span {
  display: inline-block;
  position: relative;
  top: -13px;
  left: -8px;
}
</style>